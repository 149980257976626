<template>
  <PageLayout class="home-wrapper container" :class="{isSafari:isSafari}">
    <div class="home-content d-flex flex-column justify-content-between h-100">
      <div class="d-block shadow">
        <div class="d-flex justify-content-center" v-html="svg"/>
        <h1 class="title" v-html="$t('home.title')"/>
        <p class="description" v-html="$t('home.description')"/>
      </div>
      <router-link class="btn" to="/check-in/1">{{ $t('home.btnText') }}</router-link>
    </div>
  </PageLayout>
</template>

<script>
import ResultContent from "../components/ResultContent";

export default {
  name: "Home",
  components: {
    ResultContent
  },
  data() {
    return {
      isSafari: false,
      svg: "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"270\" height=\"269\" viewBox=\"0 0 270 269\">\n" +
          "    <defs>\n" +
          "        <linearGradient id=\"rzs88zfvza\" x1=\"0%\" x2=\"99.283%\" y1=\"12.348%\" y2=\"87.112%\">\n" +
          "            <stop offset=\"0%\" stop-color=\"#E22265\"/>\n" +
          "            <stop offset=\"100%\" stop-color=\"#A238FF\"/>\n" +
          "        </linearGradient>\n" +
          "    </defs>\n" +
          "    <g fill=\"none\" fill-rule=\"evenodd\">\n" +
          "        <g>\n" +
          "            <g>\n" +
          "                <g>\n" +
          "                    <path fill=\"#E22265\" d=\"M243.523 22.564c-4.018-1.236-7.726 1.237-8.96 4.946l-8.037 27.819C203.966 21.019 165.03 0 123.616 0 55.627 0 0 55.329 0 123.64c0 68.002 55.318 123.64 123.616 123.64 39.248 0 75.408-18.237 99.201-49.765 2.163-3.091 1.545-7.419-1.545-9.583-3.09-2.163-7.417-1.545-9.58 1.546-21.015 27.819-53.155 43.892-88.076 43.892-60.572 0-110.018-49.147-110.018-109.73 0-60.584 49.446-110.04 110.018-110.04 37.393 0 71.388 18.546 91.784 49.456l-29.359-8.345c-4.014-1.237-7.726 1.236-8.962 4.945-1.233 3.71 1.236 7.728 4.948 8.964l44.81 12.673c.616.31 1.234.31 1.852.31.927 0 1.545 0 2.163-.31 2.166-.618 4.018-2.473 4.636-4.945l12.67-44.82c1.236-3.71-.927-7.727-4.635-8.964\" opacity=\".1\" transform=\"translate(-825 -331) translate(825 331) translate(10.746 10.76)\"/>\n" +
          "                    <path fill-rule=\"nonzero\" stroke=\"url(#rzs88zfvza)\" stroke-linecap=\"round\" stroke-width=\"8.06\" d=\"M121.557 184.265c-22.63-14.313-40.36-31.558-52.695-51.255-3.907-6.24-5.728-12.463-5.728-19.584v-3.063c0-8.086 3.17-15.7 8.928-21.442.755-.757 1.537-1.473 2.346-2.151 5.788-4.853 12.924-7.415 20.643-7.415 11.504 0 22.29 5.945 28.527 15.447 6.211-9.498 16.85-15.443 28.146-15.443 7.98 0 15.248 2.562 21.025 7.407.812.682 1.598 1.402 2.353 2.155 5.758 5.742 8.928 13.36 8.928 21.442v3.063c0 7.12-1.817 13.345-5.728 19.584-7.367 11.76-16.654 22.645-27.757 32.552\" opacity=\".8\" transform=\"translate(-825 -331) translate(825 331) translate(10.746 10.76)\"/>\n" +
          "                </g>\n" +
          "            </g>\n" +
          "        </g>\n" +
          "    </g>\n" +
          "</svg>\n"
    }
  },
  created() {
    sessionStorage.clear();
    if (window.innerWidth < 767) this.svg = '<svg xmlns="http://www.w3.org/2000/svg" width="201" height="200" viewBox="0 0 201 200">\n' +
        '    <defs>\n' +
        '        <linearGradient id="i4bc8gepua" x1="0%" x2="99.283%" y1="12.444%" y2="87.017%">\n' +
        '            <stop offset="0%" stop-color="#E22265"/>\n' +
        '            <stop offset="100%" stop-color="#A238FF"/>\n' +
        '        </linearGradient>\n' +
        '    </defs>\n' +
        '    <g fill="none" fill-rule="evenodd">\n' +
        '        <g>\n' +
        '            <g>\n' +
        '                <g>\n' +
        '                    <path fill="#E22265" d="M181.29 16.776c-2.992-.919-5.752.92-6.67 3.677l-5.984 20.684C151.84 15.627 122.856 0 92.025 0 41.41 0 0 41.137 0 91.925c0 50.56 41.181 91.926 92.025 91.926 29.218 0 56.137-13.56 73.85-37 1.61-2.298 1.15-5.516-1.15-7.124-2.301-1.609-5.522-1.15-7.132 1.149-15.645 20.683-39.571 32.633-65.568 32.633-45.092 0-81.902-36.54-81.902-81.584 0-45.043 36.81-81.813 81.902-81.813 27.837 0 53.144 13.789 68.328 36.77l-21.855-6.205c-2.989-.92-5.752.92-6.672 3.677-.918 2.758.92 5.745 3.683 6.665l33.359 9.422c.458.23.918.23 1.378.23.69 0 1.15 0 1.61-.23 1.613-.46 2.991-1.839 3.451-3.677l9.433-33.323c.92-2.758-.69-5.745-3.45-6.665" opacity=".1" transform="translate(-87 -149) translate(87 149) translate(8 8)"/>\n' +
        '                    <path fill-rule="nonzero" stroke="url(#i4bc8gepua)" stroke-linecap="round" stroke-width="6" d="M90.493 137c-16.847-10.642-30.046-23.463-39.23-38.108C48.357 94.253 47 89.626 47 84.332v-2.278c0-6.011 2.36-11.673 6.646-15.942.562-.563 1.145-1.095 1.746-1.6C59.703 60.906 65.014 59 70.76 59c8.564 0 16.594 4.42 21.237 11.485 4.624-7.062 12.544-11.482 20.953-11.482 5.94 0 11.352 1.905 15.652 5.507.605.507 1.19 1.042 1.752 1.602 4.286 4.27 6.646 9.933 6.646 15.942v2.277c0 5.295-1.353 9.922-4.264 14.561-5.485 8.744-12.398 16.836-20.663 24.203" opacity=".8" transform="translate(-87 -149) translate(87 149) translate(8 8)"/>\n' +
        '                </g>\n' +
        '            </g>\n' +
        '        </g>\n' +
        '    </g>\n' +
        '</svg>\n'
    let userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf('safari') != -1) {
      this.isSafari = userAgent.indexOf('chrome') <= -1;
    }
  }
}
</script>
<style lang="scss">
.home-height {
  padding: 0 15px;
  @media (max-width: 340px) {
    .title {
      max-width: 213px;
    }
    .description {
      padding-top: 0;
      max-width: 213px;
    }
  }
  @media (min-width: 341px) and (max-width: 767px) {
    .title {
      max-width: 213px;
    }
    .description {
      margin-top: 0 !important;
    }
  }
}
</style>
